import React from 'react';

const SvgFile = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M4.5 12c0-2.3 1.8-4.1 4.1-4.1h7.9c1.7 0 3 1.3 3 3s-1.3 3-3 3h-6.4c-1 0-1.9-.8-1.9-1.9 0-1.1.8-1.9 1.9-1.9h5.6v1.5H10c-.4 0-.4.8 0 .8h6.4c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5H8.5c-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.2 2.6 2.6 2.6h7.1v1.5H8.5c-2.2 0-4-1.8-4-4.1Z"
        />
    </svg>
);

export default SvgFile;
