import React from 'react';

const SvgLaptop = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M18 16.5c.8 0 1.5-.7 1.5-1.5V7.5c0-.8-.7-1.5-1.5-1.5H6c-.8 0-1.5.7-1.5 1.5V15c0 .8.7 1.5 1.5 1.5H3V18h18v-1.5h-3Zm-12-9h12V15H6V7.5Z"
        />
    </svg>
);

export default SvgLaptop;
