import React from 'react';

const SvgAttach = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        {...props}
    >
        <path d="M5.625 15C5.625 12.125 7.875 9.875 10.75 9.875H20.625C22.75 9.875 24.375 11.5 24.375 13.625C24.375 15.75 22.75 17.375 20.625 17.375H12.625C11.375 17.375 10.25 16.375 10.25 15C10.25 13.625 11.25 12.6249 12.625 12.6249H19.625V14.4999H12.5C12 14.4999 12 15.5 12.5 15.5H20.5C21.5 15.5 22.375 14.625 22.375 13.625C22.375 12.625 21.5 11.75 20.5 11.75H10.625C8.875 11.75 7.375 13.25 7.375 15C7.375 16.75 8.875 18.2499 10.625 18.2499H19.5V20.1249H10.625C7.875 20.1249 5.625 17.875 5.625 15Z" fill={color}/>
    </svg>
);

export default SvgAttach;