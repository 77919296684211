import Svg123 from 'MAINAPPJS/components/icons/components/123';
import SvgBold from 'MAINAPPJS/components/icons/components/bold';
import SvgBullets from 'MAINAPPJS/components/icons/components/bullets';
import SvgCheckedList from 'MAINAPPJS/components/icons/components/checked-list';
import SvgCondition from 'MAINAPPJS/components/icons/components/condition';
import SvgContactMinus from 'MAINAPPJS/components/icons/components/contact-minus';
import SvgCopy from 'MAINAPPJS/components/icons/components/copy';
import SvgDataEnrichment from 'MAINAPPJS/components/icons/components/data-enrichment';
import SvgExitArrow from 'MAINAPPJS/components/icons/components/exit-arrow';
import SvgImage from 'MAINAPPJS/components/icons/components/image';
import SvgInfo from 'MAINAPPJS/components/icons/components/info';
import SvgItalic from 'MAINAPPJS/components/icons/components/italic';
import SvgListNumbers from 'MAINAPPJS/components/icons/components/list-numbers';
import SvgListWithClock from 'MAINAPPJS/components/icons/components/list-with-clock';
import SvgLocked from 'MAINAPPJS/components/icons/components/locked';
import SvgMessageOutline from 'MAINAPPJS/components/icons/components/message-outline';
import SvgMigrate from 'MAINAPPJS/components/icons/components/migrate';
import SvgMore from 'MAINAPPJS/components/icons/components/more';
import SvgPlus from 'MAINAPPJS/components/icons/components/plus';
import SvgQuestion from 'MAINAPPJS/components/icons/components/question';
import SvgRedo from 'MAINAPPJS/components/icons/components/redo';
import SvgSequences from 'MAINAPPJS/components/icons/components/sequences';
import SvgUnderlined from 'MAINAPPJS/components/icons/components/underlined';
import SvgUnlocked from 'MAINAPPJS/components/icons/components/unlocked';
import SvgUnsubscribed from 'MAINAPPJS/components/icons/components/unsubscribed';
import SvgWarningOutline from 'MAINAPPJS/components/icons/components/warning-outline';
import SvgAbc from './components/abc';
import SvgActivityOutline from './components/activity-outline';
import SvgAddContact from './components/add-contact';
import SvgArrowLeft from './components/arr-left';
import SvgArrowsExpand from './components/arrows-expand';
import SvgArrowsMinimize from './components/arrows-minimize';
import SvgAttach from './components/attach';
import SvgAutomation from './components/automation';
import SvgAvatarEmpty from './components/avatar-empty';
import SvgBigHouse from './components/big-house';
import SvgBirthday from './components/birthday';
import SvgCalendar from './components/calendar';
import SvgCancel from './components/cancel';
import SvgCheckboxSquared from './components/checkbox-squared';
import SvgCheckmark from './components/checkmark';
import SvgChevronDown from './components/chevron-down';
import SvgChevronUp from './components/chevron-up';
import SvgClock from './components/clock';
import SvgClose from './components/close';
import SvgComment from './components/comment';
import SvgCompany from './components/company';
import SvgContact from './components/contact';
import SvgContacts from './components/contacts';
import SvgCourthouse from './components/courthouse';
import SvgCreditCard from './components/credit-card';
import SvgCup from './components/cup';
import SvgDeal from './components/deal';
import SvgDecreaseIndent from './components/decrease-indent';
import SvgDocument from './components/document';
import SvgDraft from './components/draft';
import SvgDropdown from './components/dropdown';
import SvgEarth from './components/earth';
import SvgEducation from './components/education';
import SvgEmail from './components/email';
import SvgEuro from './components/euro';
import SvgFastFood from './components/fast-food';
import SvgFile from './components/file';
import SvgFilter from './components/filter';
import SvgFlag from './components/flag';
import SvgGraph from './components/graph';
import SvgHeart from './components/heart';
import SvgHidden from './components/hidden';
import SvgHome from './components/home';
import SvgIncreaseIndent from './components/increase-indent';
import SvgInternational from './components/international';
import SvgLaptop from './components/laptop';
import SvgLeftAngleBracket from './components/left-angle-bracket';
import SvgLink from './components/link';
import SvgLocation from './components/location';
import SvgLockWithMinus from './components/lock-with-minus';
import SvgLockWithPlus from './components/lock-with-plus';
import SvgMerge from './components/merge';
import SvgMessage from './components/message';
import SvgNavigate from './components/navigate';
import SvgNimbleSingleStar from './components/nimble-single-star';
import SvgNote from './components/note';
import SvgNotification from './components/notification';
import SvgOpen from './components/open';
import SvgPaperPlane from './components/paper-plane';
import SvgParagraph from './components/paragraph';
import SvgPencil from './components/pencil';
import SvgPerson from './components/person';
import SvgPhone from './components/phone';
import SvgPin from './components/pin';
import SvgPlane from './components/plane';
import SvgPrivate from './components/private';
import SvgProtection from './components/protection';
import SvgRefresh from './components/refresh';
import SvgRibbon from './components/ribbon';
import SvgRightAngleBracket from './components/right-angle-bracket';
import SvgRoundedComplete from './components/rounded-complete';
import SvgRoundedWarning from './components/rounded-warning';
import SvgSendLater from './components/send-later';
import SvgSettings from './components/settings';
import SvgShopping from './components/shopping';
import SvgSparkles from './components/sparkles';
import SvgSpeechBubble from './components/speech-bubble';
import SvgStar from './components/star';
import SvgStop from './components/stop';
import SvgSuitcase from './components/suitcase';
import SvgSwap from './components/swap';
import SvgSwitch from './components/switch';
import SvgTag from './components/tag';
import SvgTagStar from './components/tag-star';
import SvgTextAlignCenter from './components/text-align-center';
import SvgTextAlignLeft from './components/text-align-left';
import SvgTextAlignRight from './components/text-align-right';
import SvgTextSize from './components/text-size';
import SvgToggle from './components/toggle';
import SvgTouch from './components/touch';
import SvgTracking from './components/tracking';
import SvgTrash from './components/trash';
import SvgUnderscore from './components/underscore';
import SvgUndo from './components/undo';
import SvgUnLink from './components/unlink';
import SvgUnmute from './components/unmute';
import SvgVisible from './components/visible';
import SvgWarning from './components/warning';
import SvgWorkflow from './components/workflow';
import SvgWrench from './components/wrench';

export const ICONS_DICTIONARY = {
    '123': Svg123,
    'abc': SvgAbc,
    'activity-outline': SvgActivityOutline,
    'add-contact': SvgAddContact,
    'arrow-left': SvgArrowLeft,
    'arrows-expand': SvgArrowsExpand,
    'arrows-minimize': SvgArrowsMinimize,
    'attach': SvgAttach,
    'automation': SvgAutomation,
    'avatar-empty': SvgAvatarEmpty,
    'big-house': SvgBigHouse,
    'birthday': SvgBirthday,
    'bold': SvgBold,
    'bullets': SvgBullets,
    'calendar': SvgCalendar,
    'cancel': SvgCancel,
    'checkbox-squared': SvgCheckboxSquared,
    'checkmark': SvgCheckmark,
    'chevron-down': SvgChevronDown,
    'chevron-up': SvgChevronUp,
    'clock': SvgClock,
    'close': SvgClose,
    'comment': SvgComment,
    'company': SvgCompany,
    'condition': SvgCondition,
    'contact': SvgContact,
    'contacts': SvgContacts,
    'contact-minus': SvgContactMinus,
    'copy': SvgCopy,
    'courthouse': SvgCourthouse,
    'credit-card': SvgCreditCard,
    'cup': SvgCup,
    'checked-list': SvgCheckedList,
    'data-enrichment': SvgDataEnrichment,
    'deal': SvgDeal,
    'decrease-indent': SvgDecreaseIndent,
    'draft': SvgDraft,
    'dropdown': SvgDropdown,
    'document': SvgDocument,
    'earth': SvgEarth,
    'education': SvgEducation,
    'email': SvgEmail,
    'euro': SvgEuro,
    'exit-arrow': SvgExitArrow,
    'fast-food': SvgFastFood,
    'file': SvgFile,
    'filter': SvgFilter,
    'flag': SvgFlag,
    'graph': SvgGraph,
    'heart': SvgHeart,
    'hidden': SvgHidden,
    'home': SvgHome,
    'image': SvgImage,
    'increase-indent': SvgIncreaseIndent,
    'info': SvgInfo,
    'international': SvgInternational,
    'italic': SvgItalic,
    'laptop': SvgLaptop,
    'left-angle-bracket': SvgLeftAngleBracket,
    'link': SvgLink,
    'list-numbers': SvgListNumbers,
    'list-with-clock': SvgListWithClock,
    'location': SvgLocation,
    'locked': SvgLocked,
    'lock-minus': SvgLockWithMinus,
    'lock-plus': SvgLockWithPlus,
    'merge': SvgMerge,
    'message': SvgMessage,
    'message-outline': SvgMessageOutline,
    'more': SvgMore,
    'migrate': SvgMigrate,
    'navigate': SvgNavigate,
    'nimble-single-star': SvgNimbleSingleStar,
    'note': SvgNote,
    'notice': SvgNote,
    'notification': SvgNotification,
    'open': SvgOpen,
    'paper-plane': SvgPaperPlane,
    'paragraph': SvgParagraph,
    'pencil': SvgPencil,
    'person': SvgPerson,
    'phone': SvgPhone,
    'pin': SvgPin,
    'plane': SvgPlane,
    'plus': SvgPlus,
    'private': SvgPrivate,
    'protection': SvgProtection,
    'question': SvgQuestion,
    'redo': SvgRedo,
    'refresh': SvgRefresh,
    'ribbon': SvgRibbon,
    'right-angle-bracket': SvgRightAngleBracket,
    'rounded-complete': SvgRoundedComplete,
    'rounded-warning': SvgRoundedWarning,
    'send-later': SvgSendLater,
    'sequences': SvgSequences,
    'settings': SvgSettings,
    'shopping': SvgShopping,
    'sparkles': SvgSparkles,
    'speech-bubble': SvgSpeechBubble,
    'star': SvgStar,
    'stop': SvgStop,
    'suitcase': SvgSuitcase,
    'swap': SvgSwap,
    'switch': SvgSwitch,
    'tag': SvgTag,
    'tag-star': SvgTagStar,
    'text-align-center': SvgTextAlignCenter,
    'text-align-left': SvgTextAlignLeft,
    'text-align-right': SvgTextAlignRight,
    'text-size': SvgTextSize,
    'toggle': SvgToggle,
    'touch': SvgTouch,
    'tracking': SvgTracking,
    'trash': SvgTrash,
    'undo': SvgUndo,
    'unlink': SvgUnLink,
    'unlocked': SvgUnlocked,
    'unmute': SvgUnmute,
    'underlined': SvgUnderlined,
    'underscore': SvgUnderscore,
    'unsubscribed': SvgUnsubscribed,
    'visible': SvgVisible,
    'warning': SvgWarning,
    'warning-outline': SvgWarningOutline,
    'workflow': SvgWorkflow,
    'wrench': SvgWrench,
};

