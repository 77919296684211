import numeral from 'numeral';

/**
 * Format large numbers into a more readable string.
 * Transforms input numbers to a string representation with 'k' for thousands,
 * 'm' for millions, and 'b' for billions.
 * For numbers less than 10,000, it returns the number as a string with thousands separated by comma.

 * - shortenAmount(100) returns '100'
 * - shortenAmount(1234) returns '1,234'
 * - shortenAmount(10000) returns '10.0k'
 * - shortenAmount(1000000) returns '1.0m'
 * - shortenAmount(123456789) returns '123.5m'
 * - shortenAmount(5000000000) returns '5.0b'
 *
 * @param {number} amount - The large numerical value to be formatted
 * @returns {string} - Returns the formatted number as a string
 */
const shortenAmount = (amount) => numeral(amount).format(amount >= 10000 ? '0,000.0a' : '0,000');

export const string = {
    shortenAmount,
};
