import React from 'react';

const SvgUnlocked = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M13.5 14.1c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5.7 1.5 1.5 1.5 1.5-.7 1.5-1.5ZM6 10.4v7.5c0 .8.7 1.5 1.5 1.5h9c.8 0 1.5-.7 1.5-1.5v-7.5c0-.8-.7-1.5-1.5-1.5H9.7V7.4c0-1.3 1-2.3 2.3-2.3.2 0 .5.1.5.1l.7-1.3s-.8-.2-1.2-.2c-2.1 0-3.8 1.7-3.8 3.8V9h-.8c-.7-.1-1.4.6-1.4 1.4Zm1.5 0h9v7.5h-9v-7.5Z"
        />
    </svg>
);

export default SvgUnlocked;
