import React from 'react';

const SvgTouch = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            d="M20.3 10.9v6.9c0 1.7-1.3 3-3 3h-5.5c-.8 0-1.6-.3-2.1-.9l-5.9-6s.9-.9 1-.9c.2-.1.4-.2.6-.2.2 0 .3 0 .5.1l3.2 1.8V4.8c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1v6.3h.7V9c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1v2.1h.8V9.7c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1v1.4h.8V11c0-.6.5-1.1 1.1-1.1.6-.2 1.2.3 1.2 1Z"
        />
        <path
            fill={color}
            d="M8 8V4.7c0-1.2 1-2.1 2.1-2.1s2.1 1 2.1 2.1v2.2c.3-.1.6-.2.9-.2h.4c.4-.6.6-1.3.6-2.1 0-2.2-1.8-4-4-4s-4 1.8-4 4c0 1.5.8 2.7 1.9 3.4Z"
        />
    </svg>
);

export default SvgTouch;
